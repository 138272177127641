import * as Type from './type';
import RPC from 'api';
import {chkMutilCallRet} from '../../../common';
import WebApp from '../../../webapp';

export function updateData (data) {
    return {
        type: Type.UPDATE_DATECONFIG_INITDATA,
        data
    };
}

export function updateTime (data) {
    return {
        type: Type.UPDATE_DATECONFIG_INITTIME,
        data
    };
}

export function updateYear (data) {
    return {
        type: Type.UPDATE_DATECONFIG_INITYEAR,
        data
    };
}

export const getTime = () => (dispatch, getState, resolve, reject) => {
    RPC.Global.getCurrentTime().then(res => {
        if (res) {
            dispatch(updateTime({
                initTime: res
            }));
            resolve(res);
        }
    }).catch(() => {
        reject(false);
    });
};

export const getHandle = (params) => (dispatch, getState, resolve, reject) => {
    RPC.System.multicall(params).then(res => {
        if (res.length > 0) {
            let tmp = res[0].params.table.TimeFormat.split(' ');
            let Time = tmp[0];
            let Hour, HourFormat, TimeFormat;
            /[A-Z]/.test(tmp[1]) === true ? Hour = 1 : Hour = 2;
            TimeFormat = tmp[0].toUpperCase();
            let dateSeparator = TimeFormat.match(/[^A-Z]/); //从格式化日期中提取连接符,DateSeparator字段偶现不存在
            Hour === 1 ? HourFormat = 'HH:mm:ss' : HourFormat = 'h:mm:ss A';
            res[0].params.table.Time = Time;
            res[0].params.table.Hour = Hour;
            res[0].params.table.moment = TimeFormat + ' ' + HourFormat;
            res[0].params.table.DateSeparator = res[0].params.table.DateSeparator ? res[0].params.table.DateSeparator : dateSeparator;
            let TimeZone = res[1] && res[1].params.table.TimeZone;
            let TimeZoneDesc = res[1] && res[1].params.table.TimeZoneDesc;
            let renderValue = ('(' + WebApp.timeZoneKey[TimeZone] + ')' + ' ' + TimeZoneDesc);
            /*
            * 保存当前时区中的第一个时区
            * 当时区与时区描述符不对应的情况下，显示当前时区中的第一条
            */
            let currFirstZone = null;
            if (!WebApp.TimeZoneExcel[renderValue] && renderValue !== '(UTC+00:00) UTC-0') {
                for (var k in WebApp.TimeZoneExcel) {
                    var key = k.replace(/\s+/g, '');
                    if (!currFirstZone) {
                        if (key.indexOf('(' + WebApp.timeZoneKey[TimeZone] + ')') > -1) {
                            currFirstZone = key;
                        }
                    }
                }
                res[1].params.table.TimeZoneDesc = currFirstZone.substr(11);
            }
            let arr = [];
            if (res.length !== 0) {
                arr = res.map((item) => {
                    item.file = item.params.table;
                    return item.file;
                });
                dispatch(updateData({
                    initData: arr
                }));
            }
            resolve(arr);
        }
    }).catch(() => {
        reject(false);
    });
};

export const updateHandle = (params) => (dispatch, getState, resolve, reject) => {
    RPC.System.multicall(params).then(res => {
        if (chkMutilCallRet(res) > 0) {
            console.log('成功');
            resolve(true);
        } else {
            resolve(false);
        }
    }).catch(() => {
        reject(false);
    });
};

export const setTime = (time, tolerance) => (dispatch, getState, resolve, reject) => {
    RPC.Global.setCurrentTime(time, tolerance).then(res => {
        console.log(res);
        resolve(true);
    }).catch(() => {
        reject(false);
    });
};

export const adjustTimeWithNTP = (params) => (dispatch, getState, resolve, reject) => {
    RPC.NetApp.instance().then(res => {
        if (res) {
            RPC.NetApp.adjustTimeWithNTP(res.result, params).then(rs => {
                console.log(rs);
            }).catch(err => {
                console.log(err);
            });
            resolve(true);
        }
    }).catch(() => {
        reject(false);
    });
};